'use strict';

module.exports = {
     ApiMainUrl: 'https://api.tamiralife.qa.metricserp.net/',
     ApiBaseUrl: 'https://api.tamiralife.qa.metricserp.net/api/backend/',
     //ApiBaseUrl:'http://localhost:8080/api/backend/',
     frontEndUrl: 'https://www.tamiralife.qa.metricserp.net/',
     cacheType: 'sessionStorage',
     debug:false,
     s3Url : "https://tamira.s3-ap-south-1.amazonaws.com",
     bucketName : "tamira",
     region : "ap-south-1",
     accessKeyId : "AKIAYHR4X7QTGUAULWOR",
     secretAccessKey : "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",
     editorKey : "syi46egl19ax4jd173sv2vb56nfloqubu47m5qlo6wikqo1d"     
};