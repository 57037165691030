import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import S3 from "react-aws-s3";
import { accessKeyId, secretAccessKey, region, s3Url, bucketName, editorKey } from '../../config';
import { useParams } from "react-router-dom";

// @mui material components
import { Box, Grid, Card, Modal, FormControl, InputLabel, Input, Select, MenuItem, FormGroup, Typography, Breadcrumbs, Link, TextField, CircularProgress } from '@mui/material';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import blogTableData from "layouts/blog/data/blogTableData";

import { useDispatch, useSelector } from 'react-redux';
import { getSingleBodyPartAction } from '../../store/BodyPartManagement/action';
import { getAllBlogAction, saveBlogAction, editBlogAction } from '../../store/BlogManagement/action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllCategorysAction } from 'store/PostCategoryManagement/action';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Blog() {
  const state = useSelector(state => state.blog);
  const categoryState = useSelector(state => state.category);
  const dispatch = useDispatch();
  const [showActive, setShowActive] = React.useState(false);
  const [file, setFile] = React.useState([]);
  const [fileName, setFileName] = React.useState("");
  const [showName, setShowName] = React.useState("");
  //Form Data
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [description1, setDescription1] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [shortDescription1, setShortDescription1] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [date, setDate] = useState(new Date());
  const [categoryId, setCategoryId] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [blogId, setBlogId] = useState('');
  const [alt, setAlt] = useState('');
  const [slug, setSlug] = useState('');
  const [relatedUrl, setRelatedUrl] = useState('');
  const [relatedBtnText, setRelatedBtnText] = useState('');
  const [author, setAuthor] = useState("");

  const [isLoading, setIsLoading] = useState(false);


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setName("");
    setDescription("");
    setDescription1("");
    setShortDescription("");
    setShortDescription1("");
    setDate("");
    setCategoryId("");
    setBlogId("");
    setMetaTag("");
    setAlt("");
    setMetaDescription("");
    setRelatedUrl("");
    setSlug("");
    setShowActive(false);
    setOpen(false);
    setRelatedBtnText("");
    setAuthor("");
    setFileName("");
  };

  const { columns, rows } = blogTableData({ handleOpen, setName, setRelatedUrl, setRelatedBtnText, setSlug, setDate, setBlogId, setShowName, setDescription, setDescription1, setAlt, setShortDescription, setShortDescription1, setCategoryId, setShowActive, setMetaTag, setMetaDescription, setAuthor });

  const getRandomFileName = () => {
    var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    var random = ("" + Math.random()).substring(2, 8);
    var random_number = timestamp + random;
    return random_number;
  };

  const fileInput = useRef();


  const handleFileInput = (e) => {
    // handle validations
    //alert(1);
    setFile(e.target.files[0]);
    setFileName(getRandomFileName() + e.target.files[0].name);
  };

  useEffect(() => {
    dispatch(getAllCategorysAction());
  }, []);


  useEffect(() => {
    //Lists
    if (categoryState.categorys !== undefined) {
      //console.log(categoryState.categorys);
      setAllCategories(categoryState.categorys);
    }
  }, [categoryState]);

  const handleCategoryChange = (e) => {
    //console.log(e.target);
    setCategoryId(e.target.value);
  }

  const allPostsReinit = () => {
    setIsLoading(true);
    setTimeout(()=> {
      setIsLoading(false);
      dispatch(getAllBlogAction());
    }, 5000);
  }
  
  const uploadPostImage = (event) => {
    event.preventDefault();
    allPostsReinit();
    const config = {
      bucketName: bucketName,
      dirName: "blog",
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey
    };
    const ReactS3Client = new S3(config);
    if (blogId) {
      if (fileName != "") {
        ReactS3Client
          .deleteFile(s3Url + "/blog/" + showName)
          .then((response) => {
            ReactS3Client.uploadFile(file, fileName)
              .then(data => {
                //console.log(data);
                //setS3FileUrl(data.location);
                const formData = {
                  "title": name,
                  "description": description,
                  "post_image": fileName,
                  "meta_title": metaTag,
                  "meta_description": metaDescription,
                  "category_id": categoryId,
                  "add_date": date,
                  "alt_tag": alt,
                  "short_description": shortDescription,
                  "slug": slug,
                  "related_link": relatedUrl,
                  "related_link_text":relatedBtnText,
                  "author":author
                }
                //console.log("edit", categoryId);
                setFileName("");
                dispatch(editBlogAction(formData, blogId));
                
                dispatch(getAllBlogAction());
                handleClose();
              })
          })
          .catch(err => console.error(err))
      } else {
        const formData = {
          "title": name,
          "description": description,
          "meta_title": metaTag,
          "meta_description": metaDescription,
          "category_id": categoryId,
          "add_date": date,
          "alt_tag": alt,
          "short_description": shortDescription,
          "slug": slug,
          "related_link": relatedUrl,
          "related_link_text":relatedBtnText,
          "author":author
        }
        setFileName("");
        dispatch(editBlogAction(formData, blogId));
        dispatch(getAllBlogAction());
        handleClose();
      }
    } else {
      if (fileName != "") {
        ReactS3Client.uploadFile(file, fileName)
          .then(data => {
            //console.log(data.location);
            //setS3FileUrl(data.location);
            const formData = {
              "title": name,
              "description": description,
              "meta_title": metaTag,
              "meta_description": metaDescription,
              "post_image": fileName,
              "category_id": categoryId,
              "add_date": date,
              "alt_tag": alt,
              "short_description": shortDescription,
              "slug": slug,
              "related_link": relatedUrl,
              "related_link_text":relatedBtnText,
              "author":author
            }
            //console.log("save", categoryId);
            setFileName("");
            dispatch(saveBlogAction(formData));
            dispatch(getAllBlogAction());
            handleClose();
          })
      } else {
        const formData = {
          "title": name,
          "description": description,
          "meta_title": metaTag,
          "meta_description": metaDescription,
          "category_id": categoryId,
          "add_date": date,
          "alt_tag": alt,
          "short_description": shortDescription,
          "slug": slug,
          "related_link": relatedUrl,
          "related_link_text":relatedBtnText,
          "author":author
        }
        setFileName("");
        dispatch(saveBlogAction(formData));
        dispatch(getAllBlogAction());
        handleClose();
      }
    }
  }

  return (
    <DashboardLayout>
      {
        isLoading && <div className='c-loader'><CircularProgress /></div>
      }
      
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/backend/dashboard">
          Home
        </Link>
        <Typography color="text.primary">Blog</Typography>
      </Breadcrumbs>
      <MDBox pt={6} pb={3} className="c-banner-management">
        <Grid container spacing={6}>
          <MDButton onClick={handleOpen}>
            Add Blog
          </MDButton>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="c-add-banner-modal"
            disableAutoFocus={true} //for disable modal
            disableEnforceFocus={true} //for disable modal
          >
            <Box sx={style}>
              <form id="my-form-id" onSubmit={uploadPostImage}>
                <FormGroup>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input" id="category-label">Category dropdown</InputLabel>
                      <Select
                        labelId="category-label"
                        id="demo-simple-select"
                        label="Category"
                        value={categoryId}
                        onChange={handleCategoryChange}
                      >
                        {
                          allCategories.map((elem, index) => (
                            <MenuItem value={elem.category_id} key={index}>{elem.category_name}</MenuItem>
                          ))
                        }

                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Title</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={name} onChange={(e) => setName(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Permalink</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={slug} onChange={(e) => setSlug(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Related Link</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={relatedUrl} onChange={(e) => setRelatedUrl(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Related Button Text</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={relatedBtnText} onChange={(e) => setRelatedBtnText(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Author</InputLabel>
                      <Input id="title" aria-describedby="my-helper-text" value={author} onChange={(e) => setAuthor(e.target.value)} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Title</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={metaTag} onChange={(e) => setMetaTag(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Meta Description</InputLabel>
                      <Input id="description" aria-describedby="my-helper-text" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Description</InputLabel>
                      {/* <Input id="description" aria-describedby="my-helper-text" value={description} onChange={(e) => setDescription(e.target.value)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={description1}
                        init={{
                          height: 500,
                          selector: 'textarea',
                          menubar: true,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount', 'directionality'
                          ],
                          toolbar: 'undo redo | formatselect | image code' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | link' +
                            'removeformat | help |' + 'ltr rtl',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          images_file_types: 'jpg,svg,webp,png',
                          image_title: true,
                          file_picker_types: 'file image media',
                          automatic_uploads: false,
                          link_context_toolbar: true,
                          link_quicklink: true,
                          /* and here's our custom image picker*/
                          file_picker_callback: (cb, value, meta) => {
                            const input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.addEventListener('change', (e) => {
                              const file = e.target.files[0];

                              const reader = new FileReader();
                              reader.addEventListener('load', () => {
                                /*
                                  Note: Now we need to register the blob in TinyMCEs image blob
                                  registry. In the next release this part hopefully won't be
                                  necessary, as we are looking to handle it internally.
                                */
                                const id = 'blobid' + (new Date()).getTime();
                                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                const base64 = reader.result.split(',')[1];
                                const blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                              });
                              reader.readAsDataURL(file);
                            });

                            input.click();
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Short Description</InputLabel>
                      {/* <Input id="shortdescription" aria-describedby="my-helper-text" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} /> */}
                      <Editor
                        apiKey={editorKey}
                        onEditorChange={(e) => setShortDescription(e)}
                        //onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={shortDescription1}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Date</InputLabel>
                      {/* <Input id="date" aria-describedby="my-helper-text" value={date} onChange={(e) => setDate(e.target.value)} /> */}
                      <DatePicker selected={date} onChange={(value) => { console.log(value), setDate(value) }} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Upload Image</InputLabel>
                      <Input type="file" ref={fileInput} id="my-input" aria-describedby="my-helper-text" onChange={handleFileInput} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={showActive ? "show" : "hide"}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Existing Image</InputLabel>
                      <Box component="img" src={s3Url + "/blog/" + showName}></Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel htmlFor="my-input">Alt Text</InputLabel>
                      <Input id="alt tag" aria-describedby="my-helper-text" value={alt} onChange={(e) => setAlt(e.target.value)} />
                    </FormControl>
                  </Grid>
                </FormGroup>
                <MDButton type="submit">
                  Submit
                </MDButton>
                <MDButton className="cancelBtn" onClick={handleClose}>
                  Cancel
                </MDButton>
              </form>
            </Box>
          </Modal>
          <Grid item xs={12} className='c-treatment-table'>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Posts
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Blog;